.body {
  text-align: center;
  background-color: #667175;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  height: 190px;
  pointer-events: none;
}

.payButton {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  margin-top: 0.5px;
  margin-bottom: 0.5px;
  width: 90px;

  img {
    width: 100%;
  }
}

.payButton:disabled {
  cursor: not-allowed !important;
}

span {
  position: relative;
}

span::after {
  position: absolute;
  content: '';
  top: 95%;
  height: 2px;
  width: 100%;
  background: #F46C00;
  right: 0;
}

.link {
  position: relative;
  color: white;
  font-size: calc(10px + 2vmin);
  text-decoration: none;
}

.link::after {
  position: absolute;
  top: -20px;
  display: inline-block;
  transform: scale(0.3);
  content: url("../../assets/images/right-arrow-orange.png");
  transition: all 300ms ease 0ms;
}
.link::after:hover {
  transition: all 300ms ease 0ms;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
